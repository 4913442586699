/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-12-03",
    versionChannel: "nightly",
    buildDate: "2024-12-03T00:06:44.055Z",
    commitHash: "bd89e5b216769168d08548f01e2b6871280ab829",
};
